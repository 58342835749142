import React, { useEffect, useState } from 'react';
import { Button, Offcanvas, Form, Spinner, ListGroup } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useExtractedImagesState } from '../../store';

import './ManualFinder.scss';
import { on } from 'events';

interface SidebarProps {
    tools: string[];
    onBack: () => void;
    onFilterMatches: (selectedTools: string[], topK: number) => void;
    handleDownload: () => void;
    isLoading: boolean;
    extractType: string;
    onExtractTypeChange: (value: string) => void;
    onGetManualsByImages: (fileNames: string[], topK: number) => void
}

const Sidebar: React.FC<SidebarProps> = ({ tools, onBack, onFilterMatches, handleDownload, isLoading, extractType, onExtractTypeChange, onGetManualsByImages }) => {
    const [checkedTools, setCheckedTools] = useState(tools.map(() => true));
    const [topK, settopK] = useState<number>(5);
    const { t } = useTranslation();
    const { selectedImages } = useExtractedImagesState();

    useEffect(() => {
        setCheckedTools(tools.map(() => true));
    }, [tools])

    const handleCheckboxChange = (index: number) => {
        const newCheckedTools = [...checkedTools];
        newCheckedTools[index] = !newCheckedTools[index];
        setCheckedTools(newCheckedTools);
    };

    const handleAction = (event: any) => {
        event.preventDefault();
        const selectedTools = tools.filter((tool, index) => checkedTools[index]);
        onFilterMatches(selectedTools, topK);
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        settopK(parseInt(value));
    };

    const onClickDownload = () => {
        handleDownload()
    };

    const getManuals = () => {
        onGetManualsByImages(selectedImages, 10)
    }

    return (
        <Offcanvas show={true} scroll={true} backdrop={false} className="custom-offcanvas">
            <Offcanvas.Header className="d-flex justify-content-center align-items-center">
                <Offcanvas.Title></Offcanvas.Title>
                <div className="text-center mt-3">
                    <Button variant="secondary" onClick={onBack} disabled={isLoading}>{t("SpecialTool.BackButton")}</Button>
                </div>
                
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div>{t("Sidebar.Info")}</div>
            <div className="mt-3">
                <Form.Check
                    type="radio"
                    id="showImagesManualSelection"
                    label={t("Sidebar.ImagesManualSelection")}
                    checked={extractType === "ImagesManual"}
                    onChange={() => onExtractTypeChange("ImagesManual")}
                    //disabled={isLoading}
                />
                <Form.Check
                    type="radio"
                    id="showImagesAutomaticSelection"
                    label={t("Sidebar.ImagesAutomaticSelection")}
                    checked={extractType === "ImagesAutomatic"}
                    onChange={() => onExtractTypeChange("ImagesAutomatic")}
                    //disabled={isLoading}
                />
                <Form.Check
                    type="radio"
                    id="showTools"
                    label={t("Sidebar.SpecialTools")}
                    checked={extractType === "SpecialTools"}
                    onChange={() => onExtractTypeChange("SpecialTools")}
                    //disabled={isLoading}
                />
            <hr />
            </div>
                {extractType === "ImagesManual" ? (
                    <div className="mt-4">
                        <h5>{t("Sidebar.SelectedImages")}</h5>
                        <ListGroup className="mb-3 scrollable-list">
                            {selectedImages.map((filename, index) => (
                                <ListGroup.Item key={index}>{filename}</ListGroup.Item>
                            ))}
                        </ListGroup>
                        <div className="d-flex justify-content-center">
                            <Button onClick={getManuals} variant="secondary" type="submit" disabled={isLoading || selectedImages.length <= 0}>
                                {isLoading ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        {' '}{t("DropZone.loading")}
                                    </>
                                ) : (
                                    t("SpecialTool.GetManualsButton")
                                )}
                            </Button>
                        </div>
                    </div>
                ) : extractType === "ImagesAutomatic" ? (
                    <></>
                  ) : (
                    <>
                        <div>{t("SpecialTool.SpecialTools")}</div>
                        {tools.length > 0 ? (
                            <>
                                <Form onSubmit={handleAction}>
                                    {tools.map((tool, index) => (
                                        <Form.Check
                                            aria-label="option 1"
                                            key={index}
                                            type="checkbox"
                                            id={`tool-${index}`}
                                            label={tool}
                                            checked={checkedTools[index]}
                                            onChange={() => handleCheckboxChange(index)}
                                            disabled={isLoading}
                                        />
                                    ))}
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("PdfFileUpload.TopKResults")}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={topK}
                                            onChange={handleNumberChange}
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                    <div className="text-center mt-3">
                                        <Button variant="secondary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    {' '}{t("DropZone.loading")}
                                                </>
                                            ) : (
                                                t("SpecialTool.FilterButton")
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                                            
                                <div className="text-center mt-3">
                                    <Button variant="secondary" onClick={onClickDownload} disabled={isLoading}>
                                        {t("SpecialTool.DownloadButton")}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className='mt-2'>{t("SpecialTool.NoToolsFound")}</div>
                        )}
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default Sidebar;
