import { useTranslation } from "react-i18next";
import { copyManualIdToClipboard, handleManualClick, ImageManual, Manual } from "../../utils/manualUtils";
import { useState } from "react";
import { Badge, Card, Container, ListGroup } from "react-bootstrap";
import './ManualFinder.scss';

interface MatchingManualsImageProps {
    manuals: ImageManual[];
}

const MatchingManualsImages: React.FC<MatchingManualsImageProps> = ({ manuals }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const onHandleManualClick = async (manual:ImageManual) => {
      handleManualClick(manual.manualId, setLoading, t);

    }

    if (manuals.length<=0) {
      return (
          <Container className="w-80 d-flex justify-content-center align-items-start" style={{  paddingTop: '20vh' }}>
          <span>{t("AutomaticImageSelection.NoManualsFound")}</span>
      </Container>
      );
  }

    return(
      <div className="d-flex justify-content-center manual-details-listgroup mt-4">
        <ListGroup className="w-75">
        {manuals.map((manual, index) => (
            <ListGroup.Item key={index} className="manual-item">
            <div className="row ">
              <div className="col-2">
              </div>
              <div className="col-8 text-center mb-0">
                <h5>{manual.itemMpText}</h5>
              </div>
              <div className="col-2 text-end mb-0">
                <p className='manual-id' onDoubleClick={() =>copyManualIdToClipboard(manual.manualId, t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)) }>{`ID: ${manual.manualId}`}</p>
              </div>
            </div>
            <div className="row align-items-center">
            <div className="col-3  text-center">
              </div>
              <div className="col-6  text-center">
              <a href="#" onClick={() => onHandleManualClick(manual)}>
                <p className="manual-name">{manual.manualName}</p>
                </a>
              </div>
              <div className="col-3  text-center">
              </div>  
            </div>
            <Card.Body>
                    <Card.Title className="fs-6">{t("AutomaticImageSelection.CountFoundImages")}</Card.Title>
                    <div className="tool-labels">
                        {manual.imageFileNameCount} {t("AutomaticImageSelection.outof")} {manual.totalImageCount}
                    </div>
                    <hr />
                    <Card.Title className="fs-6">{t("AutomaticImageSelection.FoundImages")}</Card.Title>
                    <div className="tool-labels">
                        {manual.imageFileNamesFound.map((filenmae, idx) => (
                            <Badge key={idx} className="filenamle-label">
                                {filenmae}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </ListGroup.Item>
        ))}
    </ListGroup>
    </div>
    )
}

export default MatchingManualsImages;