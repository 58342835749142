import React, { useState } from "react";
import Sidebar from "./Sidebar";
import MatchingManualsSpecialTools from "./MatchingManualSpecialTools"; // Importing Manual and MatchingManual components
import {
  handleRefineMatches,
  handleGetManualsByImages,
} from "../../api/backend"; // Assuming handleRefineMatches returns some response
import { useTranslation } from "react-i18next";
import ExtractedImages from "../PdfImageExtractor/ExtractedImages";
import { useExtractedImagesState, usePdfUploadState } from "../../store/hooks";
import {
  Manual,
  ManualItem,
  ImageManual,
  ImageManualItem,
} from "../../utils/manualUtils";
import MatchingManualsImages from "./MatchingManualImages";
import { toast } from "react-toastify";
interface SpecialToolProps {
  tools: string[];
  onBack: () => void;
  extractType: string;
  setExtractType: (value: string) => void;
}

const ManualFinder: React.FC<SpecialToolProps> = ({
  tools,
  onBack,
  extractType,
  setExtractType
}) => {
  const {manualsBasedOnSpecialTools, setManualsBasedOnSpecialTools, manualsBasedOnImages} = usePdfUploadState()
  const [imageManuals, setimageManuals] = useState<ImageManual[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setDisplayImageManuals } = useExtractedImagesState();
  const { t } = useTranslation();
  const onFilterMatches = async (filteredTools: string[], topK: number) => {
    setIsLoading(true);
    const stringifiedTools = JSON.stringify(filteredTools);
    const manualResponse = await handleRefineMatches(stringifiedTools, topK);
    if (manualResponse.length > 0) {
      const matchingManuals: Manual[] = [];
      manualResponse.forEach((item: ManualItem) => {
        if (item) {
          // const item = manualResponse[id];
          const manual: Manual = {
            manualName: item.manualName,
            manualId: item.manualID,
            matchedTools: item.matchedSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            additionalTools: item.additionalSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            missingTools: item.missingSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            itemMpText: item.itemMpText,
          };
          matchingManuals.push(manual);
        }
      });
      setManualsBasedOnSpecialTools(matchingManuals);
    }
    setIsLoading(false);
  };

  const onGetManualsByImages = async (fileNames: string[], topK: number) => {
    setIsLoading(true);
    const manualResponse = await handleGetManualsByImages(fileNames, topK);
    if (manualResponse.length > 0) {
      const _imageManuals: ImageManual[] = [];
      manualResponse.forEach((item: ImageManualItem) => {
        if (item) {
          // const item = manualResponse[id];
          const manual: ImageManual = {
            manualName: item.manualName,
            manualId: item.manualId,
            itemMpText: item.itemMpText,
            imageFileNameCount: item.imageFileNameCount,
            imageFileNamesFound: item.imageFileNamesFound,
            totalImageCount: item.totalImageCount,
          };
          _imageManuals.push(manual);
        }
      });
      setimageManuals(_imageManuals);
      setDisplayImageManuals(true);
    }
    else {
      toast(t("ExtractedImages.ManualsNotFound"), { type: "warning" });
    }
    setIsLoading(false);
  };

  const handleDownload = () => {
    const csvContent = convertToCSV(manualsBasedOnSpecialTools);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "matchingManuals.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data: Manual[]) => {
    const headers = [
      "ManualId",
      "ManualName",
      "ItemMpText",
      "MatchedTools",
      "AdditionalTools",
      "MissingTools",
    ];
    const rows = data.map((manual) => [
      manual.manualId,
      manual.manualName.replace(";", ","), // replace all ; with ,
      manual.itemMpText,
      manual.matchedTools.join(", "),
      manual.additionalTools.join(", "),
      manual.missingTools.join(", "),
    ]);

    const csvRows = [
      headers.join(";"), // header row first
      ...rows.map((row) => row.join(";")), // data rows
    ];

    return csvRows.join("\n");
  };

/*   const handleToggle = (e: any) => {
    setShowImages(e.target.checked);
  }; */

  return (
    <div>
      {/* <Row className="mb-4">
        <Col xs={8} />
        <Col xs={3} className="d-flex justify-content-end">
          <Form className="toggle-container">
            <Form.Check
              type="switch"
              id="custom-switch"
              label={t("SpecialTool.ToogleButton")}
              onChange={handleToggle}
              checked={showImages}
            />
          </Form>
        </Col>
        <Col xs={2} />
      </Row> */}
      <Sidebar
        tools={tools}
        onBack={onBack}
        onFilterMatches={onFilterMatches}
        handleDownload={handleDownload}
        isLoading={isLoading}
        extractType={extractType}
        onExtractTypeChange={setExtractType}
        onGetManualsByImages={onGetManualsByImages}
      />

      <div className="fixed-height">
        {extractType === "ImagesManual" ? (
          <ExtractedImages imageManuals={imageManuals} />
        ) : extractType === "ImagesAutomatic" ? (
          <MatchingManualsImages manuals={manualsBasedOnImages} />
        ) : (
          <MatchingManualsSpecialTools manuals={manualsBasedOnSpecialTools} />
        )}
      </div>
    </div>
  );
};

export default ManualFinder;
