import { toast } from 'react-toastify';
import { API, Amplify, Storage } from 'aws-amplify';
import {
    GetResultQueryVariables, GetResultQuery, HandleOemMatchQuery, OnCalculationFinishedSubscription, HandleOemMatchQueryVariables, OnCalculationFinishedSubscriptionVariables, ExtractSpecialToolsQuery,
    ExtractSpecialToolsQueryVariables, RefineMatchQuery, RefineMatchQueryVariables, OnspecialToolsExtractionFinishedSubscription, GetManualHtmlQuery, GetManualHtmlQueryVariables, ListManualsByImagesQueryVariables,
    ListManualsByImagesQuery, SubmitImageFeedbackMutation, SubmitImageFeedbackMutationVariables
} from '../API';
import { GraphQLQuery , GRAPHQL_AUTH_MODE, GraphQLSubscription } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { DropFile } from '../components/DropZone/DropZone';
import aws_config from '../modified_aws-export';
import { v4 as uuidv4 } from 'uuid';
import * as mutations from '../graphql/mutations';

export const uploadImage = async (uploadFile: File) => {
    Amplify.configure(aws_config);
    Storage.configure({ level: 'public' });
    return Storage.put(uploadFile.name, uploadFile, {
        contentType: "image/png",
        customPrefix: { public: "" }
    })
        .then((uploadresponse) => {
            return uploadresponse
        })
        .catch((error) => {
            toast(error.toString(), { type: "error" })
        });
}

export enum matchActions {
    match = "match",
    unmatch = "unmatch"
}

export const getFileURL = async (fileid: String) => {
    Amplify.configure(aws_config);
    Storage.configure({ level: 'public' });

    return Storage.get(`${fileid}.png`, { ...aws_config, validateObjectExistence: true, customPrefix: { public: "image_similarity_search/" } })
        .then((fileurl) => {
            let fileObject = fetch(fileurl as string).then((response) => {
                let data = response.blob().then((result) => {
                    let metadata = {
                        type: 'image/png'
                    };
                    let file = new File([result], fileid + '.png', metadata);
                    return file
                });
                return data
            });
            return fileObject
        })
        .catch((error) => {
            toast(error.toString(), { type: "error" })
        });
}

const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}

export const getDropZoneResults = async () => {
    let dropresults: DropFile[] = []
    let results = getLocalItem("results")

    if (results) {
        const json_results = JSON.parse(results)
        let json_results_keys = Object.keys(json_results)
        for (let index = 0; index < json_results_keys.length; index++) {
            const result_id = json_results_keys[index];
            let fileObject: File = await getFileURL(result_id) as File
            const dropresult_obejct: DropFile = {
                fileobject: fileObject as File,
                id: result_id,
                preview: URL.createObjectURL(fileObject as File),
                result: json_results[result_id]
            }
            dropresults.push(dropresult_obejct)
        }
    }
    return dropresults
}

export const getDropZoneResult = async (id: string) => {
    let dropresults: DropFile[] = await getDropZoneResults()
    let found_result = dropresults.find((elem) => elem.id === id)
    if (found_result) {
        return found_result
    } else {
        throw new Error("Couldn't find a result for id " + id);

    }
}

export const pineconeQuery = async (
    dropfile: DropFile,
    bucket: string,
    key: string,
    top_k: number,
    setResults: Function,
    recordEvent: Function = () => { },
    filter: string = "{}",
    onlyReturnResult: boolean = false,
    setFilterDisabled: Function = () => { },
    model: string = "vit_small_patch16_224",
    task_name: string = "TecRMI",
) => {
    var startTime = performance.now();

    try {
        const result_pinecone = await API.graphql<GraphQLQuery<GetResultQuery>>({
            query: queries.getResult,
            variables: {
                id: dropfile.id,
                bucket: bucket,
                key: key,
                top_k: top_k,
                filter: filter,
                model: model,
                task_name: task_name
            } as GetResultQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        if (result_pinecone.data) {
            let pinecone_response = result_pinecone.data?.getResult;
            if (pinecone_response) {
                let parsed_results = JSON.parse(pinecone_response);
                if ("status" in parsed_results && parsed_results["status"] === "Pending") {
                    const subscription = API.graphql<GraphQLSubscription<OnCalculationFinishedSubscription>>({
                        query: subscriptions.onCalculationFinished,
                        variables: {
                            id: parsed_results['id']
                        } as OnCalculationFinishedSubscriptionVariables,
                        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                    }).subscribe({
                        next: (event: any) => {
                            let pinecone_response = event.value.data?.onCalculationFinished.results;
                            if (pinecone_response) {
                                var endTime = performance.now();
                                recordEvent('AimModelCalculationFinished', { 'duration': `${(endTime - startTime) / 1000}s` });
                                let parsed_results = JSON.parse(pinecone_response);
                                parse(parsed_results, dropfile, setResults, setFilterDisabled);
                            }
                            subscription.unsubscribe();
                        }
                    });
                    return subscription;
                } else {
                    var endTime = performance.now();
                    recordEvent('VitModelCalculationFinished', { 'duration': `${(endTime - startTime) / 1000}s` });
                    if (onlyReturnResult) {
                        return parsed_results.matches
                    }
                    else{
                        parse(parsed_results, dropfile, setResults, setFilterDisabled);
                    }
                    
                }
            }
        }
    } catch (error) {
        if (error instanceof Error) {
            console.log(error)
            console.log(error.message);
            toast(error.message, { type: "error" });
        } else {
            toast("An unknown error occurred", { type: "error" });
        }
    }

    return null;
};


const parse = (parsed_results: any, dropfile: DropFile, setResults: Function, setFilterDisabled: Function) => {
    if (parsed_results['Error']) {
        toast(parsed_results['Error'], { type: "error" })
    } else {
        dropfile.result = parsed_results.matches //groupByProductCategory(parsed_results.matches)
        setResults(dropfile.id, JSON.stringify(dropfile.result))
        setFilterDisabled(false)
        return (dropfile.result)
    }
}

/* const groupByProductCategory = (results: any): any[] => {
    const lowestScoreObjects: any = {};
    const groupObjects: any = {};

    results.forEach((match: any) => {
        const manufacturer = match.metadata.Manufacturer;
        const score = match.score;

        if (!groupObjects[manufacturer]) {
            groupObjects[manufacturer] = []
        }
        groupObjects[manufacturer].push(match)

        if (!lowestScoreObjects[manufacturer] || score < lowestScoreObjects[manufacturer].score) {
            lowestScoreObjects[manufacturer] = match;
        }
    });
    groupObjects['grouped'] = JSON.parse(JSON.stringify(Object.values(lowestScoreObjects).sort((a: any, b: any) => a.score - b.score)));
    groupObjects['grouped'].forEach((element: any) => {
        let manufacturer = element.metadata['Manufacturer']
        element['elements'] = groupObjects[manufacturer]
    });
    groupObjects['ungrouped'] = results
    return groupObjects 
}*/

export const handleOemMatch = async (match_type: string, file_name: string, t: any, oem_file_name: string = "", metadata: string = "") => {
    await API.graphql<GraphQLQuery<HandleOemMatchQuery>>({
        query: queries.handleOemMatch,
        variables: {
            action: match_type,
            ta_file_name: file_name,
            oem_hash_file_name: oem_file_name,
            bucket: aws_config.aws_user_files_s3_bucket,
            backend_bucket: aws_config.backend.bucket_name,
            metadata: metadata,
            aim_model_name: "aim-600M-2B-imgs",
            aim_task_name: "TecRMIAIMModel",
            vit_model_name: "vit_small_patch16_224",
            vit_task_name: "TecRMI"

        } as HandleOemMatchQueryVariables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
        .then((result) => {
            if (result.data) {
                let response = result.data?.handleOemMatch
                if (response) {
                    let parsed_response = JSON.parse(response)
                    if ("message" in parsed_response) {
                        if (parsed_response["message"] === "Successful finished") {
                            if (match_type === matchActions.match) {
                                toast(t("MatchFunctionality.MatchSuccessfulMessage"), { type: "success" })
                            }
                            else if (match_type === matchActions.unmatch) {
                                toast(t("MatchFunctionality.UnmatchSuccessfulMessage"), { type: "success" })
                            }
                            else {
                                sendErrorMessageForHandleOemMatch(match_type, t)
                            }
                        }
                        else {
                            sendErrorMessageForHandleOemMatch(match_type, t)
                        }
                    }
                    else {
                        sendErrorMessageForHandleOemMatch(match_type, t)
                    }
                }
                else {
                    sendErrorMessageForHandleOemMatch(match_type, t)
                }
            }
            else {
                sendErrorMessageForHandleOemMatch(match_type, t)
            }
        })
        .catch((error) => {
            console.log(error)
            toast(error.errors, { type: "error" })
        })
}

const sendErrorMessageForHandleOemMatch = (match_type: string, t: any) => {
    if (match_type === matchActions.match) {
        toast(t("MatchFunctionality.MatchFailureMessage"), { type: "error" })
    }
    else if (match_type === matchActions.unmatch) {
        toast(t("MatchFunctionality.UnmatchFailureMessage"), { type: "error" })
    }
}

function generateUUID() {
    return uuidv4()
}

export const handleSpecialToolMatch = async (
    topK: number,
    itemMp: string,
    fileIds: string,
    setExtractedImages: Function,
    setExtractionFinished: Function,
    t: Function,
    recordEvent: Function = function () { return }
): Promise<any> => {
    try {
        const subscriptionId = generateUUID();
        var startTime = performance.now()

        // Send the initial query
        const queryResult = await API.graphql<GraphQLQuery<ExtractSpecialToolsQuery>>({
            query: queries.extractSpecialTools,
            variables: {
                pdfIds: fileIds,
                topK: topK,
                itemMp: itemMp,
                subscriptionId: subscriptionId,
                image_threshold: 0.6
            } as ExtractSpecialToolsQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        if (queryResult.data) {
            const data = queryResult.data.extractSpecialTools;
            if (data) {
                const parsedData = JSON.parse(data);

                // Check if the condition is met to trigger the subscription
                if (parsedData['Entries'] && parsedData['Entries'].length > 0) {

                    // Set up a promise to handle the subscription response
                    const resultPromiseSpecialTools = new Promise<{ message: String, specialTools: any; manuals: any}>((resolve, reject) => {
                        const subscriptionSpecialTools = API.graphql<GraphQLSubscription<OnspecialToolsExtractionFinishedSubscription>>({
                            query: subscriptions.onspecialToolsExtractionFinished,
                            variables: {
                                id: subscriptionId
                            },
                            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                        }).subscribe({
                            next: (event: any) => {
                                let specialToolsResponse = event.value.data?.onspecialToolsExtractionFinished.results;
                                specialToolsResponse = JSON.parse(specialToolsResponse)

                                if (specialToolsResponse) {
                                    const message = specialToolsResponse['message']
                                    const specialTools = JSON.parse(specialToolsResponse['specialTools']);
                                    const manuals = JSON.parse(specialToolsResponse['manuals']);
                                    var endTime = performance.now()
                                    recordEvent('SpecialToolsExtractionFinished', { 'duration': `${(endTime - startTime) / 1000}s` });
                                    // Resolve the promise with the subscription results
                                    resolve({ message, specialTools, manuals });

                                    // Clean up the subscription
                                    subscriptionSpecialTools.unsubscribe();
                                }
                            },
                            error: (error: any) => {
                                // Reject the promise in case of an error
                                reject(error);

                                // Clean up the subscription
                                subscriptionSpecialTools.unsubscribe();
                            }
                        });
                    });
                    const resultPromiseImages = new Promise<{ extractedImages: any; manualsBasedOnImages: any }>((resolve, reject) => {
                        const subscriptionImageExtraction = API.graphql<GraphQLSubscription<OnCalculationFinishedSubscription>>({
                            query: subscriptions.onCalculationFinished,
                            variables: {
                                id: subscriptionId
                            },
                            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                        }).subscribe({
                            next: (event: any) => {
                                let pinecone_response = event.value.data?.onCalculationFinished.results
                                if (pinecone_response) {
                                    let parsed_results = JSON.parse(pinecone_response)
                                    let extractedImages = parsed_results.pinecone_results
                                    let manualsBasedOnImages = parsed_results.manuals
                                    setExtractedImages(extractedImages)
                                    setExtractionFinished(true)
                                    resolve({ extractedImages, manualsBasedOnImages })

                                    if (!(extractedImages.length > 0))
                                        toast.info(t("SpecialTool.NoImagesFound"), {
                                            position: "top-center",
                                            autoClose: false,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "colored"
                                        });
                                }
                                subscriptionImageExtraction.unsubscribe();
                            },
                            error: (error: any) => {
                                // Reject the promise in case of an error
                                reject(error);

                                // Clean up the subscription
                                subscriptionImageExtraction.unsubscribe();
                            }
                        });
                    });

                    return await Promise.all([resultPromiseSpecialTools, resultPromiseImages]);
                } else {
                    // If the condition is not met, return an empty result or handle as needed
                    console.log('No entries found, subscription not triggered.');
                    return { specialTools: [], manuals: {} };
                }
            }
        }
    } catch (error) {
        console.error('Error:', error);
        throw error; // Ensure errors are propagated to caller
    }
};


export const handleRefineMatches = async (specialToolIds: String, topK: number): Promise<any> => {
    try {
        const result = await API.graphql<GraphQLQuery<RefineMatchQuery>>({
            query: queries.refineMatch,
            variables: {
                specialToolIds: specialToolIds,
                topK: topK
            } as RefineMatchQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        if (result.data) {
            const data = result.data.refineMatch;
            if (data) {
                const parsedData = JSON.parse(data);
                const manuals = JSON.parse(parsedData['manuals']);
                return manuals;  // Returning the extracted specialTools
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const handleGetManualHtml = async (manualId: String): Promise<any> => {
    try {
        const result = await API.graphql<GraphQLQuery<GetManualHtmlQuery>>({
            query: queries.getManualHtml,
            variables: {
                input: {
                    'manualId': manualId,
                    'company': aws_config.aws_ssm_param_rmi_company_name,
                    'password': aws_config.aws_ssm_param_rmi_secret_name,
                    'username': aws_config.aws_ssm_param_rmi_user_name,
                    "typeId": aws_config.aws_ssm_param_rmi_type_id,
                }
            } as GetManualHtmlQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        if (result.data) {
            const data = result.data.getManualHtml;
            if (data) {
                const parsedData = JSON.parse(data);
                return JSON.parse(parsedData.body)
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const handleGetManualsByImages = async (fileNames: String[], topK: number): Promise<any> => {
    try {
        const result = await API.graphql<GraphQLQuery<ListManualsByImagesQuery>>({
            query: queries.listManualsByImages,
            variables: {
                fileNames: fileNames,
            } as ListManualsByImagesQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        if (result.data) {
            const data = result.data.listManualsByImages;
            if (data) {
                const parsedData = JSON.parse(data);
                const manuals = JSON.parse(parsedData['manuals']);
                return manuals;  // Returning the extracted specialTools
            }
        }
    } catch (error) {
        console.error(error);
    }
};


export const handleSubmitImageFeedbackMutation = async (id:any,bucket: string, key: string, isUseful: boolean): Promise<any> => {
    try {
        const result = await API.graphql<GraphQLQuery<SubmitImageFeedbackMutation>>({
            query: mutations.submitImageFeedback,
            variables: {
                id:id,
                bucket: bucket,
                key: key,
                isUseful: isUseful
            } as SubmitImageFeedbackMutationVariables,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });
        if (result.data) {
            const data = result.data.submitImageFeedback;
            if (data) {
                const parsedData = JSON.parse(data);
                if(parsedData['statusCode']==200) return true
                return false
            }
        }
    } catch (error) {
        console.error(error);
        return false
    }
}